import * as React from "react"
import Button from "../components/button"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero-motion"
import BlockTitle from "../components/blockTitle"
import BlockImage from "../components/blockImage"
import BlockParagraph from "../components/blockParagraph"
import BlockSection from "../components/blockSection"
import BlockContentGrid from "../components/blockContentGrid"
import BlockCallout from "../components/blockCallout"
import BlockVideo from "../components/blockVideo"
import FeatureRow from "../components/featureRow"
import Testimonials from "../components/testimonials"
import TestimonialItem from "../components/testimonialItem"

// Icons
import { faTruckRampBox } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

// Images
import simpleBundlesImage from "../images/simple-bundles.png"
import videoPosterImage from "../images/simplebundles_video_poster@2x.jpg"
import logoBokksu from "../images/logos/BOKKSU-logo.png"
import logoSennheiser from "../images/logos/Sennheiser-logo.png"
import logoNuteJuice from "../images/logos/NuteJuice-logo.png"
import logoBetterFedBeef from "../images/logos/BetterFedBeef-logo.png"

const ProductPage = () => {
  const pageTitle = "Simple Bundles"
  const pageDescription = "The leading Shopify product bundling app. Break down bundles into individual SKUs for real-time inventory syncing and easy order fulfillment.";

  return (
    <Layout pageTitle={pageTitle}>

      <Seo title="Simple Bundles: Bundles Built For Shopify" description={pageDescription} />

      <Hero
        heroText="Build bundles and kits for simple order fulfillment"
        heroDesc="The fastest way to send order details to your fulfillment center for picking and packing SKU bundles and kits."
        heroButtonTextPrimary="Get Started for Free"
        heroButtonLinkPrimary="https://apps.shopify.com/simple-bundles?utm_source=freshly-commerce&utm_medium=landing-page"
        heroButtonTextSecondary="Sign In"
        heroButtonLinkSecondary="https://app.simplebundles.io/?utm_source=freshly-commerce&utm_medium=landing-page"
      />

      <BlockVideo
        blockVideoPosterImageSrc={videoPosterImage}
        blockVideoPosterImageAlt="Video"
        blockVideoURL="https://www.youtube.com/embed/mQTrOUe0TZI"
        />

      <BlockSection id="about" sectionTitle="Features">

        <FeatureRow
          featureBlockIcon1={faTruckRampBox}
          featureBlockTitle1="Fulfillment Efficiency"
          featureBlockDescription1="Break out individual items from bundles to ensure orders are fulfilled quickly and accurately."
          featureBlockIcon2={faBell}
          featureBlockTitle2="Inventory Control"
          featureBlockDescription2="Track stock levels of bundle contents separately so that you never run low on inventory or oversell."
        />
        <FeatureRow
          featureBlockIcon1={faBoxOpen}
          featureBlockTitle1="Bundle Visibility"
          featureBlockDescription1="Create bundle pages to improve marketing efforts, making it easier for customers to find and purchase them."
          featureBlockIcon2={faBullhorn}
          featureBlockTitle2="Inventory Insights"
          featureBlockDescription2="Get valuable insights into bundle sales and inventory performance for better decision-making."
        />
        <FeatureRow
          featureBlockIcon1={faFileAlt}
          featureBlockTitle1="Seamlessly Integrated"
          featureBlockDescription1="Create, edit, and manage bundles within Shopify, with seamless compatibility with fulfillment partners."
          featureBlockIcon2={faCalendarAlt}
          featureBlockTitle2="Shipping & Logistics"
          featureBlockDescription2="Manage bundles across multiple locations to optimize shipping costs and ensure timely delivery."
        />

      </BlockSection>

      <BlockImage
          blockImageSrc={simpleBundlesImage}
          blockImageAlt="Simple Bundles"
          blockImageCaption="Simple Bundles"
        />

      <BlockSection id="ourcustomers" sectionTitle="Our Customers">

        <BlockTitle>Trusted by thousands of independent stores</BlockTitle>

        <div className="block-logos g-m-1-13 g-l-4-11 g-4-11">
          <img src={logoBokksu} alt="Bokksu" />
          <img src={logoSennheiser} alt="Sennheiser" />
          <img src={logoNuteJuice} alt="Nute Juice" />
          <img src={logoBetterFedBeef} alt="Better Fed Beef" />
        </div>

        <Testimonials link="https://apps.shopify.com/simple-bundles#reviews?utm_source=freshly-commerce&utm_medium=landing-page">
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="It’s revolutionized the way we operate! It’s incredibly easy to use and help us manage our stock. We can now easily create exciting bundles with just one click, and track our stock at the same time."
              author="Liquor Loot"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="User-friendly and efficient, allowing us to build bundles from inventory and track inventory levels for each component. Highly recommend to anyone looking for a hassle-free bundle management solution."
              author="SnakeClamp Products"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="It’s a game-changer for our fulfillment process. It allows us to split our kit SKUs into individual components, making it easy to barcode scan during fulfillment."
              author="Battle Garage Racing Service"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="We’ve tried several product bundle apps, and this one is the best and most flexible solution for us. The support team is super responsive, making this a must-have for anyone doing product bundles."
              author="AromaTools"
            />
          </div>
        </Testimonials>

      </BlockSection>

      <BlockSection id="faq" sectionTitle="Frequently Asked Questions">
        <BlockContentGrid>
          <div className="block-content g-m-1-13 g-l-1-6">
            <div className="block-content-header">
              <h3 className="t-18">What types of bundles can I create with Simple Bundles?</h3>
            </div>
            <p className="t-16">With our flexible bundle options including single-SKU, multi-SKU, and infinite options, you can easily create mix-and-match bundles, multi-packs, subscription bundles, bundle deals, and more.</p>
          </div>

          <div className="block-content g-m-1-13 g-l-7-12">
            <div className="block-content-header">
              <h3 className="t-18">Can I send my bundle orders to fulfillment partners?</h3>
            </div>
            <p className="t-16">Absolutely! Simple Bundles edits Shopify orders to include bundle content, which are then sent to fulfillment partners such as 3PL, WMS, MCF, shipping software, and apps like ShipStation, FBA, and Deliverr. Customizable options are available to accommodate unique workflow needs.</p>
          </div>

          <div className="block-content g-m-1-13 g-l-1-6">
            <div className="block-content-header">
              <h3 className="t-18">Do I need to know code to set up my bundles?</h3>
            </div>
            <p className="t-16">No coding knowledge is required! Simple Bundles operates in the background to ensure seamless inventory and fulfillment operations. However, extended capabilities allow you to add front-end components with ease.</p>
          </div>

          <div className="block-content g-m-1-13 g-l-7-12">
            <div className="block-content-header">
              <h3 className="t-18">Can I create bundles that exceed Shopify's 100 variant limit?</h3>
            </div>
            <p className="t-16">Yes! You can create an unlimited number of product combinations and variations, allowing your customers to create their own custom bundle. So go ahead and get creative!</p>
          </div>

          <div className="block-content g-m-1-13 g-l-7-10">
            <p className="t-18">
              <a href="https://help.simplebundles.io/?utm_source=freshly-commerce&utm_medium=landing-page" target="_blank" rel="noreferrer">
                Visit Our Help Center
              </a>{" "}
              ⟶
            </p>
          </div>
        </BlockContentGrid>
      </BlockSection>
      <BlockCallout id="Awards">
        <BlockParagraph
          blockTitle="Awards"
          blockContent={
            <>
              <a
                href="https://www.fda.gov/food/new-era-smarter-food-safety/meet-winners-fdas-low-or-no-cost-food-traceability-challenge"
                target="_blank"
                rel="noreferrer"
              >
                FDA's New Era of Smarter Food Safety Traceability Award
              </a>
              <br />
              <br />
              <a
                href="https://www.shopify.ca/partners/blog/shopify-app-challenge-winners"
                target="_blank"
                rel="noreferrer"
              >
                Shopify's App Challenge Award
              </a>{" "}
            </>
          }
        />
      </BlockCallout>
    </Layout>
  )
}

export default ProductPage
